import React from "react";
/** Importing GraphQL Functions */
import { useStaticQuery, graphql } from "gatsby";
/** Importing styled components */
import {
  StyledFooter,
  StaticImage,
  StyledFooterRow,
  StyledFooterSocial,
  H3
} from "./StyledComponent";
/** Importing Icons */
import { Facebook, Twitter, Instagram } from "react-feather";

import { Col } from "react-flexbox-grid";

const CANALA = require('./../images/brand/canalastudio.png');

const Footer = () => {
  /** Getting data from graphQL */
  const footData = useStaticQuery(
    graphql`
      query {
        logo: file(name: { eq: "logo_white" }) {
          publicURL
          childImageSharp {
            fluid(maxHeight: 16) {
              presentationWidth
            }
          }
        }
      }
    `
  );
  return (
    <StyledFooter>
      <StyledFooterRow>
        <Col xs={12}>
          <StaticImage
            maxWidth={footData.logo.childImageSharp.fluid.presentationWidth}
            src={footData.logo.publicURL}
            alt="Website brand"
            loading="lazy"
          />
        </Col>
      </StyledFooterRow>
      <StyledFooterRow between="xs">
        <Col xs={6}>
          <StyledFooterSocial href="/" target="_blank">
            <Facebook
              color="var(--secondary)"
              size={24}
              strokeWidth={1}
            ></Facebook>
          </StyledFooterSocial>
          <StyledFooterSocial href="/" target="_blank">
            <Twitter
              color="var(--secondary)"
              size={24}
              strokeWidth={1}
            ></Twitter>
          </StyledFooterSocial>
          <StyledFooterSocial href="/" target="_blank">
            <Instagram
              color="var(--secondary)"
              size={24}
              strokeWidth={1}
            ></Instagram>
          </StyledFooterSocial>
        </Col>
        <Col xs={12}>
          <H3 align="center" color="var(--white)" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            Desarrollado por Piñata & <img style={{maxWidth: "72px", marginLeft: '4px'}} src={CANALA} alt="Canala Studio"/>
          </H3>
        </Col>
      </StyledFooterRow>
    </StyledFooter>
  );
};

export default Footer;
