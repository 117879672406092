import React, { Fragment, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
/** Importing functional components */
import SEO from "../components/Seo";
import Navbar from "./../components/Navbar";
import Footer from "../components/Footer";
import Loader from './../components/Loader';
/** Importing styled components */
import { RootLayout } from "../components/StyledComponent";


import './../styles/settings.css';
import './../styles/global.css';


const DefaultPage = ({ pageTitle, children }) => {
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoader(true)
    }, 1000);
  });
  return (
      <Fragment>
        <SEO title={pageTitle ? pageTitle : "Lourdes Islas"}></SEO>
        {!loader ? <Loader exiting={false}></Loader> : <Loader exiting={true}></Loader>}
        <RootLayout>
          <Navbar />
          {children}
          <Footer />
        </RootLayout>
      </Fragment>
  );
};

DefaultPage.propTypes = {
  pageTitle: PropTypes.string
};

export default DefaultPage;
