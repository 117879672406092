import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {

  const site = useStaticQuery(
    graphql`
      query {
        settingsYaml {
          siteTitle
          siteUrl
          siteDescription
          socialMediaCard {
            image
          }
          googleTrackingId
          browserTheme
        }
      }
    `
  );
  const { settingsYaml } = site;

  const metaDescription = description || settingsYaml.siteDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${settingsYaml.siteTitle}`}
      meta={[
        {
          name: `theme-color`,
          content: settingsYaml.browserTheme
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:image`,
          content: settingsYaml.socialMediaCard.image
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: `Piñata Tech`
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:image`,
          content: settingsYaml.socialMediaCard.image
        }
      ].concat(meta)}
    >
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${settingsYaml.googleTrackingId}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${settingsYaml.googleTrackingId}');
        `}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
