import React, { Fragment, useState } from "react";
/** Importing GraphQL Functions */
import { useStaticQuery, graphql } from "gatsby";
/** Importing functional components */

/** Importing styled components */
import {
  RootNavbar,
  StaticImage,
  NavbarLogo,
  NavbarContainerItems,
  NavbarMenu,
  H2,
  NavbarContainerItem,
  PostRow,
  Paragraph
} from "../components/StyledComponent";

/** Importing feather icons */
import { AlignJustify, X, Home, FileText, BookOpen, Phone, Image } from "react-feather";

import { Col } from "react-flexbox-grid";

const Navbar = () => {
  /** Defining states */
  const [menu, setMenu] = useState(false);
  /** Getting data from graphQL */
  const navData = useStaticQuery(
    graphql`
      query {
        logo: file(name: { eq: "logo" }) {
          publicURL
          childImageSharp {
            fluid(maxHeight: 16) {
              presentationWidth
            }
          }
        }
        getPages: allMarkdownRemark(
          filter: { fields: { contentType: { eq: "pages" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
        getGalleries: allMarkdownRemark(
          filter: { fields: { contentType: { eq: "gallery"} } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const Menu = ({ onClick }) => {
    return (
      <NavbarContainerItems onClick={onClick}>
        <PostRow>
          <Col xs={12}>
            <NavbarContainerItem to="/">
              <H2 style={{textTransform: "uppercase", marginRight: ".5rem"}} align="right" color="var(--black)">
                Inicio
              </H2>
              <Home
                size={32}
                color="var(--secondary)"
                strokeWidth={1}
                strokeLinecap="round"
              />
            </NavbarContainerItem>
          </Col>
          {navData.getPages.edges.map(el => {
            return (
              <Col xs={12} key={el.node.id}>
                <NavbarContainerItem to={el.node.fields.slug}>
                  <H2 align="right" color="var(--black)" style={{textTransform: "uppercase", marginRight: ".5rem"}}>
                    {el.node.frontmatter.title}
                  </H2>
                  <FileText
                    size={32}
                    color="var(--secondary)"
                    strokeWidth={1}
                    strokeLinecap="round"
                  />
                </NavbarContainerItem>
              </Col>
            );
          })}
          {navData.getGalleries.edges.map(el => {
            return (
              <Col xs={12} key={el.node.id}>
                <NavbarContainerItem to={el.node.fields.slug}>
                  <H2 align="right" color="var(--black)" style={{textTransform: "uppercase", marginRight: ".5rem"}}>
                    {el.node.frontmatter.title}
                  </H2>
                  <Image
                    size={32}
                    color="var(--secondary)"
                    strokeWidth={1}
                    strokeLinecap="round"
                  />
                </NavbarContainerItem>
              </Col>
            );
          })}
          <Col xs={12}>
            <NavbarContainerItem to="/blog">
              <H2 align="right" color="var(--black)" style={{textTransform: "uppercase", marginRight: ".5rem"}}>
                Blog
              </H2>
              <BookOpen
                size={32}
                color="var(--secondary)"
                strokeWidth={1}
                strokeLinecap="round"
              />
            </NavbarContainerItem>
          </Col>
          <Col xs={12}>
            <NavbarContainerItem to="/contact">
              <H2 align="right" color="var(--black)" style={{textTransform: "uppercase", marginRight: ".5rem"}}>
                Contacto
              </H2>
              <Phone
                size={32}
                color="var(--secondary)"
                strokeWidth={1}
                strokeLinecap="round"
              />
            </NavbarContainerItem>
          </Col>
        </PostRow>
        <PostRow>
          <Col xs={12}>
            <Paragraph color="var(--secondary)" align="right">
              SITIO OFICIAL DE LA ARTISTA
            </Paragraph>
          </Col>
        </PostRow>
      </NavbarContainerItems>
    );
  };

  return (
    <Fragment>
      <RootNavbar>
        <NavbarLogo to="/">
          <StaticImage
            maxWidth={navData.logo.childImageSharp.fluid.presentationWidth}
            src={navData.logo.publicURL}
            alt="Website brand"
            loading="lazy"
          />
        </NavbarLogo>
        {!menu ? (
          <NavbarMenu
            onClick={() => {
              !menu ? setMenu(true) : setMenu(false);
            }}
          >
            <AlignJustify
              size={38}
              color="#2E2D30"
              strokeWidth={1}
              strokeLinecap="round"
            />
          </NavbarMenu>
        ) : (
          <NavbarMenu
            onClick={() => {
              !menu ? setMenu(true) : setMenu(false);
            }}
          >
            <X
              size={38}
              color="#2E2D30"
              strokeWidth={1}
              strokeLinecap="round"
            />
          </NavbarMenu>
        )}
      </RootNavbar>
      {menu ? (
        <Menu
          onClick={() => {
            !menu ? setMenu(true) : setMenu(false);
          }}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Navbar;
