import styled from "styled-components";
/** Importing Link component */
import { Link } from "gatsby";
/** Importing Slider library */
import Slider from "react-slick";
/** Importing Grid library */
import { Row } from "react-flexbox-grid";

import { PropTypes } from "prop-types";

import Img from "gatsby-image";

// 0.0.0. Typography
// 1.0.0. Layout
// 2.0.0. Navbar
// 3.0.0. Images
// 4.0.0. Loader
// 5.0.0. Slider
// 6.0.0. Footer
// 7.0.0. Animations
// 8.0.0. Post
// 9.0.0. Post Page

/** =============> Typography components */
export const H1 = styled.h1`
  font-family: Playfair Display;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  font-style: normal;
  letter-spacing: 1px;
  color: ${props => `${props.color}`};
  text-align: ${props => `${props.align}`};
  letter-spacing: 1px;
  @media screen and (min-width: 320px) {
    font-size: calc(24px + 16 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
    font-size: 40px;
  }
`;

export const H2 = styled.h2`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 120%;
  font-style: normal;
  letter-spacing: 1px;
  color: ${props => `${props.color}`};
  text-align: ${props => `${props.align}`};
  letter-spacing: 1px;

  @media screen and (min-width: 320px) {
      font-size: calc(20px + 12 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
      font-size: 32px;
  }
`;

export const H3 = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => `${props.color}`};
  text-align: ${props => `${props.align}`};
  @media screen and (min-width: 320px) {
    font-size: calc(16px + 0 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
      font-size: 16px;
  }

`;

export const Paragraph = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: ${props => `${props.color}`};
  text-align: ${props => `${props.align}`};

  @media screen and (min-width: 320px) {
    font-size: calc(14px + 4 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
    font-size: 18px;
  }
`;

/** =============> Layout components */
export const RootLayout = styled.main`
  min-height: 100vh;
  width: 100%;
  background: var(--white);
  position: relative;
`;
export const RootBorder = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;
  border: 1rem solid var(--white);
  z-index: 10;
  pointer-events: none;
`;

export const HomeLayout = styled.section`
  height: 100vh;
  width: 100%;
  position: relative;
`;

export const ErrorLayout = styled.section`
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const PageLayout = styled.section`
  min-height: 100vh;
  width: 100%;
  position: relative;
`;

export const BlogPostLayout = styled.section`
  padding-top: 5rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  width: 100%;
  position: relative;
`;

export const BlogLayout = styled.section`
  min-height: 100vh;
  width: 100%;
  position: relative;
`;

export const GeneralContainer = styled.section`
  max-width: var(--general-container);
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem 0;
  min-height: ${props => `${!props.minHeight ? "" : props.minHeight}`};
`;

export const ContactContainer = styled.section`
  max-width: 36.25rem;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1rem 0;
  min-height: ${props => `${!props.minHeight ? "" : props.minHeight}`};
`;

/** =============> Navbar components */
export const RootNavbar = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  max-height: 6rem;
  padding: 0rem 0rem 0rem 1rem;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: var(--white);
    height: 1rem;
    width: 100vw;
  }
`;

export const NavbarContainerItems = styled.nav`
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 3.5rem 1rem 1rem;
  background: #fff;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 5;
  transform-origin: center top;
  animation: navEntering 150ms ease-in;
  animation-fill-mode: both;
`;

export const NavbarContainerItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  padding: 0.5rem 0;
  transition: all ease-in 150ms;
  border-radius: 40px;
  transform: translatez(0);
  &:hover {
    padding: 0.5rem .5rem 0.5rem 0;
    background: #ededed;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const NavbarLogo = styled(Link)`
  background: var(--white);
  padding: 0.5rem 1rem 0.5rem 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const NavbarMenu = styled.button`
  appearance: none;
  background: var(--white);
  border: 0;
  height: 3.5rem;
  width: 3.5rem;
  padding: 0.5rem;
  outline: 0;
  cursor: pointer;
`;

/** =============> Image components */
export const StaticImage = styled.img`
  max-width: ${props => `${props.maxWidth}px`};
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const FluidImage = styled(Img)`
  width: 100%;
  height: 100%;
`;

export const FluidImageGallery = styled(Img)`
  max-width: ${props => `${props.maxWidth}`};
  height: 100%;
  max-height: ${props => `${!props.maxHeight ? "" : props.maxHeight}`};
`;

StaticImage.propTypes = {
  maxWidth: PropTypes.number
};

/** =============> Loader components */
export const StyledLoader = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: var(--black);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  opacity: 1;
  &.exit {
    animation: exiting 300ms ease-in;
    animation-fill-mode: both;
  }
  img {
    max-width: 16rem;
    width: 100%;
  }
`;

/** =============> Slider component */
export const StyledCarousel = styled(Slider)`
  height: ${props => `${props.height}`};
  width: 100%;

  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100vh;
  }

  .slick-active {
    img {
      animation: zoomIn 5s cubic-bezier(0.25, 0.1, 0.25, 1);
      animation-fill-mode: both;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slick-dots {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    li {
      display: inline-flex;
      margin: 0 0.5rem;
    }
    button  {
      width: 2rem;
      cursor: pointer;
      height: 0.5rem;
      border: 0;
      background-color: rgba(255, 255, 255, 0.3);
      font-size: 0;
      appearance: none;
      outline-color: var(--secondary);
    }
    .slick-active {
      button {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
`;

export const StyledCarouselSlide = styled.figure`
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  height: 100%;
`;

export const StyledCarouselSlideContainer = styled.section`
  z-index: 1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: var(--general-container);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledCarouselSlideContent = styled.figcaption`
  background-color: var(--white);
  padding: 1rem 1rem 2rem;
  z-index: 1;
  position: absolute;
  bottom: 4rem;
  left: 2rem;
  width: calc(100% - 4rem);
  max-width: 38rem;

  @media screen and (min-width: 760px) {
    bottom: 7rem;
    padding: 1rem 1rem 3rem;
  }
`;

export const StyledCarouselButton = styled(Link)`
  height: 3.5rem;
  width: 3.5rem;
  background: var(--black);
  position: absolute;
  bottom: -0.5rem;
  right: -0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  @media screen and (min-width: 760px) {
    height: 4.5rem;
    width: 4.5rem;
  }
`;

StyledCarousel.propTypes = {
  height: PropTypes.string
};

/** =============> Footer component */
export const StyledFooter = styled.footer`
  background: var(--black);
  padding: 1rem 1rem 0;
`;
export const StyledFooterRow = styled(Row)`
  padding-bottom: 1rem;
`;
export const StyledFooterBrand = styled.img`
  max-width: 11rem;
  width: 100%;
  display: inline-block;
`;

export const StyledFooterSocial = styled.a`
  max-width: 2rem;
  margin-right: 1rem;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  transition: all ease 150ms;
  border-radius: 50%;

  &:hover {
    background: var(--secondary);
    svg {
      stroke: var(--black);
    }
  }
`;

/** =============> Post component */
export const PostRow = styled(Row)`
  margin-bottom: 1rem;
`;

export const PostCover = styled.figure`
  position: relative;
  height: 22rem;
  @media screen and (min-width: 760px) {
    height: 32rem;
  }
`;

export const PostCoverImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledCarouselPost = styled(Slider)`
  height: ${props => `${props.height}`};
  width: 100%;

  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slick-active {
    img {
      animation: zoomIn 5s cubic-bezier(0.25, 0.1, 0.25, 1);
      animation-fill-mode: both;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slick-dots {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    li {
      display: inline-flex;
      margin: 0 0.5rem;
    }
    button  {
      cursor: pointer;
      width: 2rem;
      height: 0.5rem;
      border: 0;
      background-color: rgba(255, 255, 255, 0.3);
      font-size: 0;
      appearance: none;
      outline-color: var(--secondary);
    }
    .slick-active {
      button {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
`;

export const StyledCarouselSlidePost = styled.figure`
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  height: 100%;
`;

/** =============> Post page */
export const BlogPostCard = styled.article`
  width: 100%;
  background: var(--white);
  position: relative;
  height: 20rem;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const BlogPostCardContent = styled.section`
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  height: 12rem;
  width: calc(100% - 2.75rem);
  background: var(--white);
  padding: 0.5rem;
`;

export const BlogPostVideo = styled.section`
  width: 100%;
  height: 100%;
  iframe {
    height: 100%;
    width: 100%;
  }
`;

export const TextField = styled.input`
  appearance: none;
  border: var(--secondary) 2px solid;
  height: 2.5rem;
  width: 100%;
  font-size: .875rem;
  padding: 0 1rem;
  font-family: Montserrat;
  margin-bottom: .5rem;
`;

export const TextArea = styled.textarea`
  appearance: none;
  border: var(--secondary) 2px solid;
  width: 100%;
  font-size: .875rem;
  padding: .5rem 1rem;
  font-family: Montserrat;
  margin-bottom: .5rem;
  height: 7rem;
`;

export const ContactButton = styled.a`
  background: var(--primary);
  width: 100%;
  line-height: 3.5rem;
  font-size: .875rem;
  color: var(--white);
  letter-spacing: .2px;
  font-family: Montserrat;
  margin-bottom: .5rem;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  margin-bottom: 1rem;
`;
