import React from 'react';
/** Importing styled components */
import { StyledLoader, GeneralContainer } from './StyledComponent';
const logo = require('./../images/brand/logo-loader.png');

const Loader = ({exiting}) => {
  return (
    <StyledLoader className={exiting ? "exit" : "entry"}>
      <section>
        <GeneralContainer>
          <img src={logo} alt="Logo for loading display"></img>
        </GeneralContainer>
      </section>
    </StyledLoader>
  );
};

export default Loader;
